import { Form } from "../formsTypes";

const resForms: Form[] = [
  {
    name: "Système de variation de puissance en éclairage extérieur",
    id: "RES-EC-103",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/RES-EC-103.pdf",
    type: "Éclairage",
    fields: [
      {
        id: "amount",
        title: "Montant en kWh cumac par W installé",
        type: "value",
        value: "8",
      },
      {
        id: "power",
        title: "Puissance installée de l'éclairage régulé en W",
        type: "number",
      },
    ],
    attachments: [],
  },
  {
    name: "Rénovation d'éclairage extérieur",
    id: "RES-EC-104",
    formUrl: "https://gdceeforms.blob.core.windows.net/v61/RES-EC-104.pdf",
    type: "Éclairage",
    fields: [
      {
        id: "case",
        title: "Type de luminaire neuf",
        type: "choice",
        choices: [
          {
            name: "Cas n°1 : efficacité lumineuse ≥ 90 lumens par Watt et ULOR ≤ 1 % (ou, pour les luminaires à LED, ULR ≤ 3%)",
            value: "9300",
          },
          {
            name: "Cas n°2 : efficacité lumineuse  ≥ 70 lumens par Watt et ULOR ≤ 10 % (ou, pour les luminaires à LED, ULR ≤ 15 %)",
            value: "7200",
          },
        ],
      },
      {
        id: "lights",
        title: "Nombre de luminaires installés",
        type: "number",
      },
    ],
    attachments: [],
  },
];

export default resForms;

export const activeForms: string[] = resForms.filter((f) => f.fields.length > 0).map((f) => f.id);
