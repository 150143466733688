import { TextField } from "@mui/material";
import { CSSProperties, ChangeEvent, FC, useState } from "react";

export const formatPhoneNumber = (number?: string): string => {
  if (number?.startsWith("0") && number.length === 10) {
    return `${number.substring(0, 2)} ${number.substring(2, 4)} ${number.substring(4, 6)} ${number.substring(
      6,
      8,
    )} ${number.substring(8, 10)}`;
  }
  if (number?.startsWith("+33") && number.length === 12) {
    return `${number.substring(0, 4)} ${number.substring(4, 6)} ${number.substring(6, 8)} ${number.substring(
      8,
      10,
    )} ${number.substring(10, 12)}`;
  }

  // Guadeloupe : +590
  // Réunion : +262
  // Guyanne : +594
  // Martinique : +596
  // Mayotte : +262
  if (
    (number?.startsWith("+590") ||
      number?.startsWith("+262") ||
      number?.startsWith("+594") ||
      number?.startsWith("+596")) &&
    number.length === 13
  ) {
    return `${number.substring(0, 4)} ${number.substring(4, 7)} ${number.substring(7, 9)} ${number.substring(
      9,
      11,
    )} ${number.substring(11, 13)}`;
  }
  // Luxembourg : +352
  if (number?.startsWith("+352") && number.length === 12) {
    return `${number.substring(0, 4)} ${number.substring(4, 6)} ${number.substring(6, 9)} ${number.substring(9, 12)}`;
  }
  // Belgique : +32
  if (number?.startsWith("+32") && number.length === 11) {
    return `${number.substring(0, 3)} ${number.substring(3, 4)} ${number.substring(4, 7)} ${number.substring(
      7,
      9,
    )} ${number.substring(9, 11)}`;
  }
  // Suisse : +41
  if (number?.startsWith("+41") && number.length === 12) {
    return `${number.substring(0, 3)} ${number.substring(3, 5)} ${number.substring(5, 8)} ${number.substring(
      8,
      10,
    )} ${number.substring(10, 12)}`;
  }
  if (!new RegExp("^[0-9 +]*$").test(number || "")) return "";
  return number || "";
};

interface PhoneTextFieldProps {
  value: string;
  label: string;
  onChange: (n: string) => void;
  style?: CSSProperties;
}

const PhoneTextField: FC<PhoneTextFieldProps> = ({ value, label, onChange, style }) => {
  const [formattedValue, setFormattedValue] = useState(formatPhoneNumber(value));

  const handleChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
    const rawValue = event.target.value.replace(/ /g, "").replace(/-/g, "").replace(/\./g, "");
    if (!new RegExp("^[0-9 +]*$").test(rawValue)) return;
    const newFormattedValue = formatPhoneNumber(rawValue);
    setFormattedValue(newFormattedValue);
    onChange(rawValue);
  };

  const maxLength = (): number => {
    if (formattedValue.startsWith("0")) return 14;
    if (formattedValue.startsWith("+33")) return 16;
    if (
      formattedValue.startsWith("+590") ||
      formattedValue.startsWith("+262") ||
      formattedValue.startsWith("+594") ||
      formattedValue.startsWith("+596")
    )
      return 17;
    if (formattedValue.startsWith("+352") || formattedValue.startsWith("+32")) return 15;
    if (formattedValue.startsWith("+41")) return 16;
    return 17;
  };

  return (
    <TextField
      style={style}
      label={label}
      value={formattedValue}
      inputProps={{
        maxLength: maxLength(),
      }}
      onChange={handleChange}
      autoComplete="off"
    />
  );
};

PhoneTextField.defaultProps = {
  style: undefined,
};

export default PhoneTextField;
