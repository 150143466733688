/* eslint-disable @typescript-eslint/naming-convention */
// __typename is a GraphQL naming convention!

import {
  Article,
  ArticleInput,
  Attachment,
  AttachmentInput,
  BonusDetails,
  BonusDetailsInput,
  Company,
  CompanyInput,
  DataField,
  DataFieldInput,
  Document,
  DocumentInput,
  GlobalRate,
  GlobalRateInput,
  Maybe,
  MemberSignature,
  MemberSignatureInput,
  Operation,
  OperationInput,
  Project,
  ProjectDetails,
  ProjectDetailsInput,
  ProjectUpdate,
  Rate,
  RateInput,
  RateThreshold,
  RateThresholdInput,
  Signature,
  SignatureInput,
  Signer,
  SignerInput,
  User,
  UserUpdate,
  Validation,
  ValidationInput,
  ValidationStatus,
} from "./generated/graphql";

export const globalRateToGlobalRateInput = (globalRate: GlobalRate): GlobalRateInput => {
  const { __typename, ...result } = globalRate;
  return result;
};

export const companyToCompanyInput = (company: Company): CompanyInput => {
  const { __typename, ...result } = company;
  return result;
};
export const userToUserUpdate = (user: User): UserUpdate => {
  const company = user.company ? companyToCompanyInput(user.company) : undefined;
  const { __typename, active, name, joined, userCharter, claims, ...result } = user;
  return { ...result, company };
};

export const rateThresholdsToRateThresholdsInput = (t: RateThreshold[]): RateThresholdInput[] =>
  t.map((rt) => {
    const { __typename, ...result } = rt;
    return result;
  });

export const validationToValidationInput = (validation: Validation): ValidationInput => {
  const { __typename, ...result } = validation;
  return result;
};

export const attachmentToAttachmentInput = (a: Attachment): AttachmentInput => {
  const { __typename, id, concerns, ...result } = a;
  const r = result.validation
    ? { ...result, validation: validationToValidationInput(result.validation as Validation) }
    : result;
  return r;
};

export const articleToArticleInput = (a: Article): ArticleInput => {
  const { __typename, creation, latestModification, attachments, ...result } = a;
  return result;
};

export const dateConvertToString = (date: Date | string): string => {
  const actualDate: Date = typeof date === "string" ? new Date(date) : date;
  return actualDate.toLocaleDateString();
};

export const dataDateConvertToDate = (dataDate: string): Date | null => {
  if (dataDate.length !== 10) return null;
  return new Date(`${dataDate}T12:00:00Z`);
};

export const dateConvertToDataDate = (date: Date | null): string => {
  const finalDate = date === null ? null : new Date(date.getTime() + 12 * 3600 * 100);
  return finalDate !== null && !Number.isNaN(finalDate.getTime()) ? finalDate.toISOString().substring(0, 10) : "";
};

export const timeConvertToString = (date: Date | string): string => {
  const actualDate: Date = typeof date === "string" ? new Date(date) : date;
  return `${actualDate.getHours()}h${actualDate.getMinutes().toString().padStart(2, "0")}`;
};

export const TextDateConvertToNumber = (date: string): number => {
  return parseInt(date.replace(/-/g, ""), 10);
};

export const dateConvertToNumber = (date: Date): number => {
  return parseInt(
    `${date.getFullYear()}${(date.getMonth() + 1).toString().padStart(2, "0")}${date
      .getDate()
      .toString()
      .padStart(2, "0")}`,
    10,
  );
};

const getWeek = (date: Date): number => {
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  const startYearDate = new Date(date.getFullYear(), 0, 4);
  return (
    1 + Math.round(((date.getTime() - startYearDate.getTime()) / 86400000 - 3 + ((startYearDate.getDay() + 6) % 7)) / 7)
  );
};

export const dateConvertDateToWeekNumber = (date: Date): number => {
  return parseInt(`${date.getFullYear()}${getWeek(date).toString().padStart(2, "0")}`, 10);
};
export const dateConvertStringToWeekNumber = (date: string): number => {
  const dateFormated = new Date(date);
  return parseInt(`${dateFormated.getFullYear()}${getWeek(dateFormated).toString().padStart(2, "0")}`, 10);
};

export const nbDaysElapsed = (dateString?: string | null): number => {
  if (!dateString) return 0;
  const d = new Date(dateString).getTime();
  return (new Date().getTime() - d) / (1000 * 3600 * 24);
};

export const formatNumber = (n?: number | string, fixed?: number, padEnd?: boolean): string => {
  if (!n) return "0";
  const tmpNumber = typeof n === "string" ? parseFloat(n) : n;
  // Remove insignificant decimal trailing zeros
  const finalNumber = typeof fixed !== "undefined" ? parseFloat(tmpNumber.toFixed(fixed)) : `${tmpNumber}`;
  const result = finalNumber
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
    .replace(".", ",");
  return result.indexOf(",") === -1 || !fixed || !padEnd ? result : result.padEnd(result.indexOf(",") + fixed + 1, "0");
};

export const formatValue = (value?: string, showZero?: boolean, fixed?: number, padEnd?: boolean): string => {
  if (!value) return "";
  const nValue = Number.parseFloat(value);
  if (Number.isNaN(nValue)) return value;
  const formatted = formatNumber(nValue, fixed, padEnd);
  return formatted === "0" && !showZero ? "" : formatted;
};

export const formatCapacity = (cap?: string): string => {
  if (!cap) return "";
  // Capacity is expressed in MWhca
  const nCap = Number.parseInt(cap, 10);
  if (nCap >= 1000000) {
    return `${(nCap / 1000000).toFixed(2).replace(".00", "")} TWhca`;
  }
  if (nCap >= 1000) {
    return `${(nCap / 1000).toFixed(2).replace(".00", "")} GWhca`;
  }
  return `${nCap} MWhca`;
};

export const formatThreshold = (rt: RateThreshold): string =>
  `${formatCapacity(rt.minimumCapacity as string)} <= C${
    rt.maximumCapacity ? ` < ${formatCapacity(rt.maximumCapacity)}` : ""
  }`;

export const formatSiret = (siret?: string): string =>
  siret ? `${siret.substring(0, 3)} ${siret.substring(3, 6)} ${siret.substring(6, 9)} ${siret.substring(9)}` : "";

const domTomDepartments = ["97", "98"];

export const getMetDomTom = (zipCode: string): number => {
  const dep = zipCode.substring(0, 2);
  if (domTomDepartments.indexOf(dep) !== -1) return 2;
  return 1;
};

const rateToRateInput = (rate: Rate): RateInput => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { __typename, ...result } = rate;
  return result;
};

export const computePeriods = (rateHistory: Rate[], reverseAndEmpty = true): Rate[] => {
  const result = rateHistory.map((rh) => rateToRateInput(rh));
  result.sort((rh1, rh2) => ((rh1.period || "") > (rh2.period || "") ? 1 : -1));
  const currentPeriod = new Date().toISOString().substring(0, 7);
  const existingPeriods = rateHistory?.map((rh) => rh.period) || [];
  const firstPeriod = existingPeriods[0] || currentPeriod;
  const firstPeriodYear = parseInt(firstPeriod.substring(0, 4), 10);
  const firstPeriodMonth = parseInt(firstPeriod.substring(5, 7), 10);
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1;
  for (let y = firstPeriodYear; y <= currentYear; y += 1) {
    for (let m = 1; m <= 12; m += 1) {
      const period = `${y}-${m.toString().padStart(2, "0")}`;
      if ((y === firstPeriodYear && m < firstPeriodMonth) || existingPeriods.includes(period)) {
        // eslint-disable-next-line no-continue
        continue;
      }
      if (y === currentYear && m > currentMonth) {
        break;
      }
      result.push({ period, value: reverseAndEmpty ? "" : null });
    }
  }
  return reverseAndEmpty === true ? result.reverse() : result;
};

export const detailsToDetailsInput = (details: ProjectDetails): ProjectDetailsInput => {
  const { __typename, ...result } = details;
  return result;
};
const dataFieldToDataFieldInput = (dataField: DataField): DataFieldInput => {
  const { __typename, ...result } = dataField;
  return result;
};
export const emptySigner = { company: { siret: "" } };
export const signerToSignerInput = (signer?: Maybe<Signer> | User): SignerInput => {
  if (!signer) return emptySigner;
  const company = signer.company ? companyToCompanyInput(signer.company) : { siret: "" };
  const { __typename, id, userCharter, ...result } = signer as User;
  return { ...result, company };
};
export const bonusDetailsToBonusDetailsInput = (bonusDetails: BonusDetails): BonusDetailsInput => {
  const { __typename, ...result } = bonusDetails;
  return result;
};
export const operationsToOperationsInput = (operations: Operation[]): OperationInput[] => {
  return operations.map((o) => {
    const { __typename, computation, ...result } = o;
    result.data = result.data ? result.data.map((d) => dataFieldToDataFieldInput(d as DataField)) : [];
    result.bonusDetails = result.bonusDetails
      ? result.bonusDetails.map((b) => bonusDetailsToBonusDetailsInput(b as BonusDetails))
      : undefined;
    return result;
  });
};

export const signatureToSignatureInput = (signature: Signature): SignatureInput => {
  const { __typename, ...result } = signature;
  return result;
};
export const memberSignatureToMemberSignatureInput = (memberSignature: MemberSignature): MemberSignatureInput => {
  const { __typename, ...result } = memberSignature;
  return result;
};

export const emptyValidation: Validation = {
  userId: "",
  status: ValidationStatus.Rejected,
  date: "",
};
export const documentToDocumentInput = (document: Document): DocumentInput => {
  const { __typename, ...result } = document;
  result.details = result.details ? (signatureToSignatureInput(result.details) as Signature) : {};
  result.details.members = result.details?.members
    ? result.details.members.map((m) => memberSignatureToMemberSignatureInput(m) as MemberSignature)
    : [];
  result.validation = result.validation
    ? (validationToValidationInput(result.validation) as Validation)
    : emptyValidation;
  return result;
};

export const projectToProjectUpdate = (project: Project): ProjectUpdate => {
  const client = signerToSignerInput(project.client);
  const clientSigner = signerToSignerInput(project.clientSigner);
  const userSigner = project.userSigner ? signerToSignerInput(project.userSigner) : undefined;
  const details = project.details ? detailsToDetailsInput(project.details) : undefined;
  const operations = project.operations ? operationsToOperationsInput(project.operations as Operation[]) : undefined;
  const { __typename, totalComputation, ...result } = project;
  return { ...result, client, clientSigner, details, operations, userSigner };
};
