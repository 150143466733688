import { Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { attachmentToAttachmentInput, dateConvertToString } from "../../data/dataConvertors";
import {
  AdminActionType,
  Attachment,
  AttachmentType,
  Project,
  Validation,
  ValidationStatus,
} from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import Validator from "./Validator";
import DocumentValidationModal from "../components/DocumentValidationModal";
import { UserContext } from "../../data/contexts/UserContext";

interface BillingRequestProps {
  project: Project;
  isUserBillingRequest?: boolean;
}

const BillingRequest: FC<BillingRequestProps> = ({ project, isUserBillingRequest }) => {
  const [sendingBillingRequest, setSendingBillingRequest] = useState(false);
  const { t } = useTranslation(["project", "global"]);
  const { prepareBillingRequest, updateProject, markActionDone } = useContext(ProjectsContext);
  const { enqueueSnackbar } = useSnackbar();
  const [openValidationModal, setOpenValidationModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { userInfo } = useContext(UserContext);
  const billingRequest = isUserBillingRequest ? project?.userBillingRequest : project?.billingRequest;
  const billing = isUserBillingRequest ? project?.userBilling : project?.billing;

  const sendBillingRequest = async (): Promise<void> => {
    setSendingBillingRequest(true);
    const result = await prepareBillingRequest(project.id);
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    }
    setSendingBillingRequest(false);
  };

  const openBillingRequest = (): void => {
    const url = billingRequest?.url;
    if (url) window.open(`${url}?t=${new Date().getTime()}`);
  };

  const openBill = (): void => {
    const url = billing?.url;
    if (url) window.open(`${url}?t=${new Date().getTime()}`);
  };

  const validateTheBill = async (date: string, amount: string): Promise<void> => {
    setIsLoading(true);
    markActionDone({
      projectId: project.id,
      type: isUserBillingRequest ? AdminActionType.ValidateUserBilling : AdminActionType.ValidateBilling,
    });
    const result = await updateProject(
      isUserBillingRequest
        ? {
            id: project.id,
            userBilling: {
              ...attachmentToAttachmentInput(project.userBilling as Attachment),
              validation: {
                status: ValidationStatus.Accepted,
                userId: userInfo?.id || "",
                date: new Date().toISOString(),
              },
              date,
              amount,
            },
          }
        : {
            id: project.id,
            billing: {
              ...attachmentToAttachmentInput(project.billing as Attachment),
              validation: {
                status: ValidationStatus.Accepted,
                userId: userInfo?.id || "",
                date: new Date().toISOString(),
              },
              date,
              amount,
            },
          },
    );
    if (!result) {
      enqueueSnackbar(t("global:errorWhileUpdating"), { variant: "error" });
    } else enqueueSnackbar(t("global:updateSuccess"), { variant: "success" });
    setIsLoading(false);
    setOpenValidationModal(false);
  };

  const tryValidateBill = async (): Promise<boolean> => {
    setOpenValidationModal(true);
    return Promise.resolve(true);
  };

  const refuseBill = (validation: Validation): Promise<boolean> => {
    markActionDone({
      projectId: project.id,
      type: isUserBillingRequest ? AdminActionType.ValidateUserBilling : AdminActionType.ValidateBilling,
    });
    return updateProject(
      isUserBillingRequest
        ? {
            id: project.id,
            userBilling: {
              ...attachmentToAttachmentInput(project.userBilling as Attachment),
              validation,
            },
          }
        : {
            id: project.id,
            billing: {
              ...attachmentToAttachmentInput(project.billing as Attachment),
              validation,
            },
          },
    );
  };
  return (
    <>
      <div className="row-top space-between margin-top">
        <div>
          <Typography variant="h6" className="margin-bottom">
            {t(isUserBillingRequest ? "userBillingRequestTwo" : "billingRequestTwo")}
          </Typography>
          <Typography>
            {t(
              billingRequest
                ? billingRequest.validation?.status === ValidationStatus.Accepted
                  ? "billingRequestFinished"
                  : "billingRequestOngoing"
                : "noBillingRequestYet",
              {
                replace: {
                  date: dateConvertToString(
                    (billingRequest?.validation?.status === ValidationStatus.Accepted
                      ? billingRequest?.validation?.date
                      : billingRequest?.creationDate) || "",
                  ),
                },
              },
            )}
          </Typography>
        </div>
        <div className="column-right">
          <GdButton
            label={t(billingRequest ? "downloadBillingRequest" : "sendBillingRequest")}
            color={billingRequest ? "secondary" : "primary"}
            className="margin-bottom"
            onClick={billingRequest ? openBillingRequest : sendBillingRequest}
            isLoading={sendingBillingRequest}
          />
        </div>
      </div>
      {billing ? (
        <>
          <Validator
            validation={billing?.validation}
            validateOnServer={tryValidateBill}
            refuseOnServer={refuseBill}
            leftText={
              <div>
                <Typography variant="h6" className="margin-bottom">
                  {t("billTwo")}
                </Typography>
                {!billing?.validation ? undefined : (
                  <Typography>
                    {t(billing.validation.status === ValidationStatus.Accepted ? "billValidated" : "billRefused", {
                      replace: {
                        date: dateConvertToString(billing.validation.date),
                        reason: billing.validation.comment,
                      },
                    })}
                  </Typography>
                )}
              </div>
            }
            topButton={<GdButton label={t("seeBill")} color="secondary" onClick={openBill} />}
            projectId={project.id}
            type={AttachmentType.Billing}
          />
          <DocumentValidationModal
            open={openValidationModal}
            onClose={() => setOpenValidationModal(false)}
            documentValidation={validateTheBill}
            url={billing?.url}
            type="billing"
            isLoading={isLoading}
          />
        </>
      ) : undefined}
    </>
  );
};

BillingRequest.defaultProps = {
  isUserBillingRequest: false,
};

export default BillingRequest;
