import { Card, Typography } from "@mui/material";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { formatSiret, formatValue } from "../../data/dataConvertors";
import { Gender, Maybe, Signer } from "../../data/generated/graphql";
import GdButton from "../../utils/GdButton";
import { formatPhoneNumber } from "../../common/PhoneTextField";

interface SignerDetailsProps {
  title: string;
  modifySigner?: () => void;
  signer?: Maybe<Signer>;
}

const SignerDetailsReadOnly: FC<SignerDetailsProps> = ({ title, modifySigner, signer }) => {
  const { t } = useTranslation(["user", "project"]);
  return (
    <Card variant="outlined" style={{ width: 350, padding: 16 }}>
      <Typography variant="h6" className="margin-bottom">
        {title}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:fullName")}{" "}
        {signer?.gender && signer?.firstName && signer?.lastName ? (
          `${t(signer?.gender === Gender.M ? "civ.mr" : "civ.mrs")} ${signer?.firstName} ${signer?.lastName}`
        ) : (
          <b>{t("project:missing")}</b>
        )}
      </Typography>
      <Typography className="signer-form-info">
        {t("email")}
        {" : "}
        {signer?.email ? signer?.email : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("phone")}
        {" : "}
        {signer?.phone ? formatPhoneNumber(signer.phone) : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:role")} {signer?.role ? signer?.role : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:companyName")} {signer?.company.name ? signer?.company.name : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:companyType")}{" "}
        {signer?.company.companyType ? signer?.company.companyType : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:shareCapital")}{" "}
        {signer?.company.shareCapital ? (
          `${formatValue(signer?.company.shareCapital, false, 0)} €`
        ) : (
          <b>{t("project:missing")}</b>
        )}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:rcs")} {signer?.company.rcs ? signer?.company.rcs : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("siret")} : {signer?.company.siret ? formatSiret(signer?.company.siret) : <b>{t("project:missing")}</b>}
      </Typography>
      <Typography className="signer-form-info">
        {t("project:companyAddress")}{" "}
        {signer?.company.address && signer?.company.zipCode && signer?.company.city ? (
          `${signer?.company.address}, ${signer?.company.zipCode} ${signer?.company.city}`
        ) : (
          <b>{t("project:missing")}</b>
        )}
      </Typography>
      {modifySigner ? (
        <GdButton label={t("project:modifySigner")} onClick={modifySigner} className="margin-top" />
      ) : undefined}
    </Card>
  );
};

SignerDetailsReadOnly.defaultProps = { signer: undefined, modifySigner: undefined };

export default SignerDetailsReadOnly;
