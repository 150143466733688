import { TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ProjectsContext } from "../../data/contexts/ProjectsContext";
import { AdminActionType, ProcedureStatus, Project } from "../../data/generated/graphql";
import GdAlert from "../../utils/GdAlert";
import GdButton from "../../utils/GdButton";

interface ForceRateButtonProps {
  project: Project;
}

const ForceRateButton: FC<ForceRateButtonProps> = ({ project }) => {
  const [showAlert, setShowAlert] = useState(false);
  const [showAlertConventionIsExisting, setShowAlertConventionIsExisting] = useState(false);
  const [forcing, setForcing] = useState(false);
  const [rate, setRate] = useState(`${project.totalComputation?.rate?.value}`);
  const { forceRate, markActionDone } = useContext(ProjectsContext);
  const { t } = useTranslation("project");
  const { enqueueSnackbar } = useSnackbar();

  const forceRateOnProject = async (): Promise<void> => {
    if (Number.isNaN(parseFloat(rate))) enqueueSnackbar(t("rateIsInvalid"), { variant: "error" });
    else {
      setShowAlert(false);
      setShowAlertConventionIsExisting(false);
      setForcing(true);
      const result = await forceRate(project.id, rate);
      if (!result) enqueueSnackbar(t("forceRateFailed"), { variant: "error" });
      else {
        enqueueSnackbar(t("rateForced"), { variant: "success" });
        markActionDone({ projectId: project.id, type: AdminActionType.UpdateManualRate });
      }
      setForcing(false);
    }
  };

  const tryForceRateOnProject = (): void => {
    if (project.convention) {
      if (project.convention.status !== ProcedureStatus.Finished) {
        setShowAlertConventionIsExisting(true);
      } else {
        setShowAlertConventionIsExisting(false);
        enqueueSnackbar(t("forceRateIsImpossible"), { variant: "error" });
      }
    } else forceRateOnProject();
  };

  return (
    <>
      <GdButton
        label={t(project.totalComputation?.rateIsManual ? "forceRateAgain" : "forceRate")}
        onClick={() => setShowAlert(true)}
        isLoading={forcing}
      />
      <GdAlert
        open={showAlert}
        onClose={() => setShowAlert(false)}
        title={t("forceRateForProject")}
        body={
          <TextField
            label={t("manualRate")}
            value={rate}
            className="margin-top"
            onChange={(e) => setRate(e.target.value)}
            fullWidth
          />
        }
        okButtonClick={() => tryForceRateOnProject()}
        okButtonDisabled={rate === project.totalComputation?.rate?.value}
        cancelButton
      />
      <GdAlert
        open={showAlertConventionIsExisting}
        onClose={() => {
          setShowAlertConventionIsExisting(false);
          setShowAlert(false);
        }}
        title=""
        body={<Typography>{t("tryForceRateAgain")}</Typography>}
        okButtonClick={() => forceRateOnProject()}
        cancelButton
      />
    </>
  );
};

export default ForceRateButton;
