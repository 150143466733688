import { Operation, ProcedureStatus, Project, Sector, ValidationStatus } from "../data/generated/graphql";
import { getFormsForSector } from "./form/FormHelper";
import { Form } from "./form/formsTypes";

const defaultComputationDate = "2023-09-30T01:00:00.000Z";

export const dontTouchComputationOnProject = (project: Project): boolean =>
  Boolean(
    project.convention &&
      project.convention.status !== ProcedureStatus.Refused &&
      project.convention.status !== ProcedureStatus.Expired &&
      project.convention.status !== ProcedureStatus.Asked,
  );

export const isLatestForm = (
  operation: Operation,
  sector: Sector,
  zipCode: string,
  originalOperation?: Operation,
): boolean => {
  const form = getFormsForSector(sector, zipCode).find((f) => f.id === operation.formId);
  let isLatest = Boolean(
    (!form?.startDate || form?.startDate < (operation.computation?.computationDate || defaultComputationDate)) &&
      !operation.computation?.validity?.toLowerCase().includes("error"),
  );
  if (typeof originalOperation !== "undefined") {
    if (
      form?.startDate &&
      form?.startDate > (originalOperation?.computation?.computationDate || defaultComputationDate)
    ) {
      form.history?.forEach((f) => {
        if (
          (f.startDate && f.startDate < (originalOperation?.computation?.computationDate || defaultComputationDate)) ||
          !f.startDate
        ) {
          isLatest = false;
        }
      });
    }
  }
  return isLatest;
};

export const getApplicableForm = (
  operation: Operation,
  sector: Sector,
  zipCode: string,
  originalOperation?: Operation,
): Form | undefined => {
  const form = getFormsForSector(sector, zipCode).find((f) => f.id === operation.formId);
  const computationDate = originalOperation?.computation?.computationDate || defaultComputationDate;

  if (typeof form !== "undefined" && computationDate.length > 0 && form.startDate && form.startDate > computationDate) {
    const history = form.history || [];
    // eslint-disable-next-line no-restricted-syntax
    for (const f of history) {
      if (!f.startDate || f.startDate < computationDate) {
        return f;
      }
    }
  }
  return form;
};

export const isProjectObsolete = (project: Project): boolean => {
  if (dontTouchComputationOnProject(project)) return false;

  return (
    (project.operations || []).findIndex(
      (o) => o && !isLatestForm(o, project.details?.sector || Sector.Ind, project.client?.company?.zipCode || ""),
    ) !== -1
  );
};

export const projectHaveFinalOperations = (project: Project): boolean => {
  const { finalOperations } = project;
  return typeof finalOperations !== "undefined" && finalOperations !== null && finalOperations.length > 0;
};

export const quoteIsAccepted = (project: Project): boolean => {
  const isAccepted = Boolean(project.quote && project.quote.validation?.status === ValidationStatus.Accepted);
  return isAccepted;
};
